@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap'); */
@layer base {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background: transparent !important;
    transition: background 5000s ease-in;
  }

  @font-face {
    font-family: "Lato";
    src: url("../public/fonts/Lato-Hairline.woff");
  }
  @font-face {
    font-family: "Lato";
    src: url("../public/fonts/Lato-Heavy.woff");
  }
  @font-face {
    font-family: "Lato";
    src: url("../public/fonts/Lato-Medium.woff");
  }
  @font-face {
    font-family: "Lato";
    src: url("../public/fonts/Lato-Semibold.woff");
  }
  @font-face {
    font-family: "Lato";
    src: url("../public/fonts/Lato-Thin.woff");
  }
  @font-face {
    font-family: "Lato";
    src: url("../public/fonts/Lato-Light.woff");
  }
  @font-face {
    font-family: "Lato";
    src: url("../public/fonts/Lato-Regular.woff");
  }

  @font-face {
    font-family: "Lato";
    src: url("../public/fonts/Lato-Bold.woff");
  }
  @font-face {
    font-family: "Lato";
    src: url("../public/fonts/Lato-Black.woff");
  }

  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply transition duration-200 px-6 h-[54px] py-2 border-[1.6px] border-theme-input font-medium w-full  bg-transparent rounded-lg  placeholder-gray-500;
  }
  [type="text"]:focus,
  [type="email"]:focus,
  [type="url"]:focus,
  [type="password"]:focus,
  [type="number"]:focus,
  [type="date"]:focus,
  [type="datetime-local"]:focus,
  [type="month"]:focus,
  [type="search"]:focus,
  [type="tel"]:focus,
  [type="time"]:focus,
  [type="week"]:focus,
  [multiple]:focus,
  textarea:focus,
  select:focus,
  [type="text"]:focus-visible,
  [type="email"]:focus-visible,
  [type="url"]:focus-visible,
  [type="password"]:focus-visible,
  [type="number"]:focus-visible,
  [type="date"]:focus-visible,
  [type="datetime-local"]:focus-visible,
  [type="month"]:focus-visible,
  [type="search"]:focus-visible,
  [type="tel"]:focus-visible,
  [type="time"]:focus-visible,
  [type="week"]:focus-visible,
  [multiple]:focus-visible,
  textarea:focus-visible,
  select:focus-visible {
    @apply border-theme-primary  shadow-none;
    --tw-ring-color: #f3f4f4 !important;
    --tw-ring-shadow: unset !important;
    outline: unset !important;
  }
  [type="text"].green:focus,
  [type="email"].green:focus,
  [type="url"].green:focus,
  [type="password"].green:focus,
  [type="number"].green:focus,
  [type="date"].green:focus,
  [type="datetime-local"].green:focus,
  [type="month"].green:focus,
  [type="search"].green:focus,
  [type="tel"].green:focus,
  [type="time"].green:focus,
  [type="week"].green:focus,
  [multiple].green:focus,
  textarea.green:focus,
  select.green:focus {
    @apply border-green-dark;
    --tw-ring-color: #107c10 !important;
    border-color: #107c10 !important;
  }
  [type="text"].red:focus,
  [type="email"].red:focus,
  [type="url"].red:focus,
  [type="password"].red:focus,
  [type="number"].red:focus,
  [type="date"].red:focus,
  [type="datetime-local"].red:focus,
  [type="month"].red:focus,
  [type="search"].red:focus,
  [type="tel"].red:focus,
  [type="time"].red:focus,
  [type="week"].red:focus,
  [multiple].red:focus,
  textarea.red:focus,
  select.red:focus {
    @apply border-red-dark;
    --tw-ring-color: #a80000 !important;
    border-color: #a80000 !important;
  }
  [type="checkbox"]:focus,
  [type="radio"]:focus {
    --tw-ring-color: #131d23;
  }
  tbody {
    display: block;
    max-height: 320px;
    overflow: auto;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  * {
    line-height: 1.25;
    font-style: normal;
    font-size: 16px;
  }
  svg {
    font-size: inherit;
    color: inherit;
  }

  html {
    scroll-behavior: smooth;
  }
  body {
    @apply break-words;
  }
  a {
    @apply no-underline;
  }
  h1,
  .h1 {
    @apply text-theme-primary font-lato text-[28px] font-black tablet:text-[48px] md:text-[48px] lg:text-[50px] leading-[1.1];
  }
  h2,
  .h2 {
    @apply text-theme-primary font-semibold  font-lato tablet:text-[30px] text-[25px];
    line-height: 1.25;
  }
  h3,
  .h3 {
    @apply text-theme-primary font-lato lg:text-[24px] font-semibold;
    line-height: 1.25;
  }
  h4,
  .h4 {
    @apply text-theme-primary font-lato lg:text-[18px] font-semibold;
    line-height: 1.25;
  }

  p {
    @apply text-[16px] font-normal;
    line-height: 1.3;
  }
  a {
    text-decoration: none;
    @apply text-inherit;
  }

  .container {
    @apply mx-auto xxl:max-w-[1550px] tablet:max-w-[700px] md:max-w-[900px] lg:max-w-[1250px] xl:max-w-[1380px] px-4 tablet:px-0 sm:max-w-full !important;
  }
}
@layer components {
  .shadow-outline {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  }

  .absolute-img {
    @apply inset-0 w-full h-full absolute object-cover;
  }

  .basic-multi-select .select__input-container {
    @apply h-[48px] !important;
  }

  .hover-img {
    @apply hover:scale-110 duration-500 transition-all;
  }
  .center {
    @apply flex justify-center items-center;
  }
  .form {
    @apply w-full tablet:mx-auto max-w-[570px] lg:ml-32;
  }
  .btn {
    @apply h-[48px] lg:h-[50px] xl:h-[54px] border-2  cursor-pointer  font-medium text-[18px] px-[30px]  rounded-lg  center gap-2;
  }
  .btn.btn-primary {
    @apply bg-theme-primary text-white rounded-full;
  }
  .btn.btn-secondary {
    @apply bg-white text-theme-primary;
  }
  .btn.btn-outline {
    @apply border border-white;
  }
  .btn.btn-outline-primary {
    @apply border-theme-primary   border-2 rounded-full;
  }
  .btn.btn-outline-primary:hover {
    @apply text-theme-textlight duration-500 border-theme-textlight;
  }
  .editbtn {
    @apply btn btn-secondary bg-white rounded-full h-[40px] w-[40px] tablet:h-[50px] tablet:w-[50px] lg:h-[60px] lg:w-[60px] px-0 py-0 text-base md:text-2xl hover:bg-theme-textdark hover:text-white hover:border-theme-textdark duration-500 transition-all;
  }

  .gradient {
    background: linear-gradient(
        90.05deg,
        #131d23 0.04%,
        rgba(19, 29, 35, 0) 99.95%
      ),
      url(/public/images/collection/headerbg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
  }
  .shadow4 {
    box-shadow: 0px 3px 16px rgba(47, 83, 109, 0.12);
    @apply transition-all duration-200 rounded-2xl;
  }
  .shadowb {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .heading {
    @apply relative mx-auto text-center;
  }
  .heading::after {
    content: " ";
    @apply bg-theme-primary absolute bottom-[-20px] w-[102px] h-[3px] left-[50%] translate-x-[-50%];
  }
  .nav-tabs .nav-link.active {
    @apply bg-theme-primary;
    border: 0;
    color: white;
  }
  .help-blog-swiper {
    @apply py-6 !important;
  }
  .popular-swiper {
    @apply py-12 pb-20 !important;
  }

  .popular-swiper .swiper-pagination .swiper-pagination-bullet-active {
    @apply bg-theme-primary   p-1 ring-theme-primary outline-1 relative  !important;
  }
  .popular-swiper .swiper-pagination .swiper-pagination-bullet-active::after {
    content: "";
    @apply absolute inset-[-3px] left-[-4px] top-[-4px] bg-white  rounded-full border-theme-primary border-2  w-[16px] h-[16px] z-[-1];
  }

  .body1 {
    @apply text-[15px] font-medium;
    line-height: 1.3;
  }

  .body2 {
    @apply tablet:text-[16px] text-[14px];
    line-height: 1.3;
  }

  .subtitle1 {
    @apply tablet:text-[16px] text-[14px] font-normal;
    line-height: 1.3;
  }

  .subtitle2 {
    @apply lg:text-[25px] text-[18px] font-normal;
    line-height: 1.3;
  }

  .caption {
    @apply text-[16px] font-normal;
    line-height: 1.3;
  }
  .text-12 {
    @apply text-[12px];
  }
  .text-13 {
    @apply text-[13px];
  }

  .faq .accordion-button:not(.collapsed)::after {
    background-image: url("../public/images/home/xmark.png");
    transform: rotate(-135deg);
  }
  .faq .accordion-button::after {
    background-image: url("../public/images/home/xmark.png");
  }
  .switch input:checked ~ label {
    @apply bg-blue-500;
  }
  .switch input:checked ~ label > span {
    @apply left-[39px];
  }
  .status_input input:nth-of-type(1):checked + label {
    @apply bg-theme-primary text-white;
  }
  .status_input input:nth-of-type(2):checked + label {
    @apply bg-theme-primary text-white;
  }
  .custom-scrollbar-md::-webkit-scrollbar {
    @apply rounded-lg w-[10px] bg-theme-input !important;
  }
  .custom-scrollbar-md::-webkit-scrollbar-thumb {
    @apply rounded-lg w-[10px] bg-theme-primary !important;
  }
}
@layer utilities {
  .home-header {
    height: calc(100vh - 65px);
  }

  @screen lg {
    .home-header {
      height: calc(100vh - 75px);
    }
  }
}
